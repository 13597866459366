<template>
  <UButton
    :size="size"
    :color="color"
    variant="ghost"
    class="-ml-2"
    target="_blank"
    :label="data?.toLowerCase()"
    :ui="{variant: { ghost: `text-gray-700 dark:text-gray-200 hover:text-${color}-500 dark:hover:text-${color}-500`}}"
    @click.stop="handle"
  />
</template>

<script lang="ts" setup>
import type { ButtonColor, ButtonSize } from '#ui/types'
const props = withDefaults(
  defineProps<{ 
    data: string
    type: 'phone' | 'email'
    color?: ButtonColor
    size?: ButtonSize
  }>(), {
    color: 'secondary',
    size: 'sm'
  }
)
const handle = () => {
  const { trackEvent } = useTracking()
  trackEvent('use_external_contact_method', {
    event_category: 'engagement',
    event_label: props.type,
    event_value: props.data
  })


  let payload = ''
  if (props.type === 'email') payload = 'mailto:' + props.data.trim() 
  else payload = 'tel:' + removeNonNumericChars(props.data)
  navigateTo(payload, {
    external: true,
  })
}

function removeNonNumericChars(str: string): string {
  // Use a regular expression to match only numeric characters
  const numericCharsOnly = str?.replace(/[^0-9]/g, '');
  return numericCharsOnly;
}

</script>

<style>

</style>