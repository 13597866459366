<template>
  <BadgeGroup>
    <UBadge 
      v-for="val in values" 
      color="secondary"
      size="xs"
      class="whitespace-nowrap"
      variant="soft"
    >
      <span>{{val}}</span>
    </UBadge>
  </BadgeGroup>
  
</template>

<script lang="ts" setup>
const props = defineProps<{ data: string[], long?: boolean}>()


const { sectors } = useConstants()
const values = computed(() => {
  return props.data.map(sec => {
    const rec = sectors.find(s => sec === s.id || sec === s.label || s.altNames.includes(sec))

    if (!rec) return ''

    return rec[props.long ? 'label' : 'id']

  }).filter(val => !!val)
  
})
</script>

<style>

</style>